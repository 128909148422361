<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialogPreview" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Preview</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogPreview = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text class="dialog-preview-test">
            <!-- <b-embed type="iframe" aspect="16by9" :src="location_origin+'/preview-test/dist/index.html?test_id='+test_id" ></b-embed> -->
            <iframe
              :src="
                location_origin +
                '/preview-test/dist/index.html?test_id=' +
                test_id
              "
              style="height: 100%; width: 100%"
              frameborder="0"
            ></iframe>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <button class="btn btn-light-success text-uppercase mr-2" @click="btnDraftTest">Save as Draft</button>
            <button class="btn btn-light-success text-uppercase mr-2" @click="submit">Submit</button> -->
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogPreview = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
export default {
  name: "PreviewLessTest",
  data() {
    return {
      optionSkill: [],
    };
  },
  computed: {
    ...mapState({
      test_id: (state) => state.informationTestBuilderStore.test_id,
    }),
    ...mapGetters({
      // skills:      'contentTestBuilderStore/skills',
    }),
    location_origin: {
      get() {
        return window.location.origin;
      },
    },
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogPreview: {
      get() {
        return this.$store.state.informationTestBuilderStore.dialogPreview;
      },
      set(value) {
        this.$store.commit(
          "informationTestBuilderStore/setDialogPreview",
          value
        );
      },
    },
  },
  methods: {
    btnDraftTest() {
      let seft = this;
      Swal.fire({
        title: "Do you want save draft?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, save it!",
      }).then((result) => {
        if (result.value) {
          seft.is_call_api = true;
          let data = {
            test_id: seft.test_id,
            id_draft: 1,
          };
          ApiService.post("prep-app/test/draft", data)
            .then(function (res) {
              if (res.status == 200) {
                seft.is_call_api = false;
                // seft.$toasted.success('Submit successfully!!', { theme: "toasted-primary", position: "top-right", duration : 4000 });
                // if(seft.courseId != null) {
                //   seft.$router.push({ name: 'CourseContent', params: { id: seft.courseId, dataTest: res.data.data }})
                // } else {
                //   seft.$router.push({ name: 'TestList'})
                // }
              }
            })
            .catch(function (error) {
              if (error) {
                seft.is_call_api = false;
                seft.$toasted.error(
                  "Submit not successfully. Please try again or contact admin !!",
                  {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  }
                );
              }
            });
        }
      });
    },
    submit() {
      let seft = this;
      Swal.fire({
        title: "Do you want save?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, save it!",
      }).then((result) => {
        if (result.value) {
          seft.is_call_api = true;
          let data = {
            test_id: seft.test_id,
            is_draft: 0,
          };
          ApiService.post("prep-app/test/draft", data)
            .then(function (res) {
              if (res.status == 200) {
                seft.is_call_api = false;
                // seft.$toasted.success('Submit successfully!!', { theme: "toasted-primary", position: "top-right", duration : 4000 });
                // if(seft.courseId != null) {
                //   seft.$router.push({ name: 'CourseContent', params: { id: seft.courseId, dataTest: res.data.data }})
                // } else {
                //   seft.$router.push({ name: 'TestList'})
                // }
              }
            })
            .catch(function (error) {
              if (error) {
                seft.is_call_api = false;
                seft.$toasted.error(
                  "Submit not successfully. Please try again or contact admin !!",
                  {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  }
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.dialog-preview-test {
  overflow: hidden !important;
  height: 10000px !important;
}
</style>
